import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Cover, FAQs, GridResults, Image, Input, Layout, Link, SEO } from 'components';
import { routes } from 'utils/routes';
import { shippingFaqsData } from 'utils/config/faqs';
import reviewSelfies from 'utils/config/reviewSelfies';

import content from './shipping.yml';

const formFeedback = [
  'Sending your message...',
  'Your message was successfully sent. We will get back to you as soon as possible.',
  'Ups, something just happened:',
];

const ShippingPage = ({ pageContext: { locale }, location }) => {
  const [formStatus, handleFormStatus] = useState(-1);
  const [formError, handleFormError] = useState(null);
  const sending = () => handleFormStatus(0);
  const success = () => handleFormStatus(1);
  const failed = () => handleFormStatus(2);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleFormError(null);
    sending();
    // todo: update url
    const url = 'https://www.straightteethdirect.com/wp-json/contact-form-7/v1/contact-forms/1149/feedback';
    const data = new FormData(ev.target);

    fetch(url, {
      method: 'POST',
      body: data,
      credentials: "same-origin",
    }).then(function(response) {
      // response.status     //=> number 100–599
      // response.statusText //=> String
      // response.headers    //=> Headers
      // response.url        //=> String
      success();
      // return response.text();
    }, function(error) {
      failed();
      handleFormError(error.message);
    });
  };

  return (
    <Layout locale={locale}>
      <SEO
        title={content.title}
        description={content.description}
        lang={locale}
        keywords={content.keywords}
        location={location}
        image={content.socialImage}
      />

      <Cover
        title='Free International Courier Deliveries'
        image={{ url: 'pages/shipping_cover.png', alt: 'Free global shipping' }}
        button={{ label: 'Get started', ...routes.getStarted }}
        trustpilot
        overlap
      />

      <h3 className='section-title'>Worldwide shipping</h3>
      <p className='t-center mt-10 mb-40'>Shipped to over 50 countries!</p>

      <div className='section-shipping'>
        <h3 className='section-title'>We do ship pretty much everywhere except to the countries listed below</h3>
        <div className='flex-wrap'>
          <div className='flags-wrapper'>
            <Image url='flags/flag_south_america.png' alt='South America' caption='South America' />
            <Image url='flags/flag_usa.png' alt='USA' caption='USA' />
            <Image url='flags/flag_spain.png' alt='Spain' caption='Spain' />
          </div>
          <div className='flags-wrapper'>
            <Image url='flags/flag_india.png' alt='India' caption='India' />
            <Image url='flags/flag_pakistan.png' alt='Pakistan' caption='Pakistan' />
            <Image url='flags/flag_bangladesh.png' alt='Bangladesh' caption='Bangladesh' />
          </div>
          <div className='flags-wrapper'>
            <Image url='flags/flag_philippines.png' alt='Philippines' caption='Philippines' />
            <Image url='flags/flag_ukraine.png' alt='Ukraine' caption='Ukraine' />
            <Image url='flags/flag_russia.png' alt='Russia' caption='Russia' />
          </div>
        </div>

        <h3 className='section-title mb-50 mt-90'>We are expanding quickly.<br/>Where would you like us to ship to?</h3>
        <form onSubmit={handleSubmit} className='form-wrapper'>
          <Input id='state' name='Yourcity' placeholder='Your state *' required/>
          <Input id='email' name='Youremail' placeholder='Your email *' type='email' required />
          <Input id='country' name='Yourcountry' placeholder='Country request *' required />
          {formStatus > -1 &&
            <p className='ml-20 mr-20'>
              <b>
                {formFeedback[formStatus]}
                {formError && <span className='block red'><br/>{formError}</span>}
              </b>
            </p>
          }
          <div className='shipping-actions'>
            <Button label='Vote now' level='outline' />
            <p>Are you a dentist? <Link to={routes.dentists.path} {...routes.dentists}>Register your interest</Link></p>
          </div>
        </form>
      </div>

      <FAQs title='Shipping FAQs' faqsData={shippingFaqsData} className='mb-50 mt-90' />

      <h3 className='section-title mb-50 mt-90'>#MySmileDelivered reviews</h3>

      <GridResults link={{ label: 'View more results' }} pictures={reviewSelfies} trustpilot slide hasAction className='mb-50' />
    </Layout>
  );
};

ShippingPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default ShippingPage;
